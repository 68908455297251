import React, { useState, useEffect } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
export default function ParallaxHeader({
  image,
  children,
  factor,
  className,
  offset,
}) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <header
      className={`w-full relative flex flex-col items-center justify-center mt-[50px] ${className}`}
    >
      <div className="absolute h-full w-full  top-0 left-0">
        <GatsbyImage
          image={image}
          objectFit={"cover"}
          objectPosition={`bottom`}
          style={{
            height: "100%",
            width: "100%",
          }}
          imgStyle={{
            height: "120%",
            objectPosition: `0 calc(80% + ${
              (scrollPosition - (offset || 0)) * factor
            }px)`,
          }}
          layout="fullWidth"
          alt=""
          formats={["auto", "webp", "avif"]}
        />
      </div>
      <div className="absolute h-full w-full header-gradient top-0 left-0"></div>
      {children}
    </header>
  );
}
