import React from "react";
import { graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import ParallaxHeader from "../components/ParallaxHeader";
import { motion } from "framer-motion";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
export const TeamPageTemplate = ({ title, image, intro, members }) => {
  const headerImage = getImage(image) || image;
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <ParallaxHeader className="h-60" image={headerImage} factor={0.7}>
        <motion.h1
          initial={{ x: -10, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "ease",
            },
          }}
          className="z-10 text-white text-5xl "
        >
          {title}
        </motion.h1>
      </ParallaxHeader>
      <section className="flex flex-col items-center justify-center w-full bg-white">
        <div className="container p-4 sm:p-10">
          <div className="flex flex-col-reverse items-center justify-center xl:grid xl:grid-cols-3 items-center">
            <div className="col-span-2 flex flex-col items-center md:items-start">
              <h1 className="text-darkbrown text-2xl mb-5">{intro.header}</h1>
              <p className="text-darkbrown/[0.5] mb-5">{intro.content}</p>
              <Link to={`/careers`}>
                <button
                  className={`text-md text-white bg-navy p-3 rounded-sm hover:bg-navyHover w-80 flex flex-row justify-center items-center`}
                >
                  {intro.button.text}
                </button>
              </Link>
            </div>
            <div>
              <GatsbyImage image={getImage(intro.image)} />
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center justify-center w-full bg-beige-300/[0.5]">
        <div className="container p-4 sm:p-10">
          <div className="grid grid-cols-1 gap-6">
            {members.map((member, idx) => (
              <BioCard key={idx} member={member} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default function TeamPage({ data }) {
  const { frontmatter } = data.markdownRemark;
  return (
    <>
      <Helmet>
        <title>Our Team - Brown Construction Services</title>
      </Helmet>
      <TeamPageTemplate
        title={frontmatter.title}
        image={frontmatter.image}
        intro={frontmatter.intro}
        members={frontmatter.members}
      />
    </>
  );
}

function BioCard({ member }) {
  return (
    <div className="p-6 bg-white rounded shadow-lg mb-3">
      <h1 className="text-navy text-2xl">{member.name}</h1>
      <p>{member.bio}</p>
    </div>
  );
}

export const teamPageQuery = graphql`
  query TeamPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        intro {
          header
          content
          button {
            text
            link
          }
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 500)
            }
          }
        }
        members {
          name
          bio
        }
      }
    }
  }
`;
